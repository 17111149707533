/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import {Duration} from "luxon";
import PropTypes from "prop-types";

import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import useInboxContractNames from "../../components/hooks/useInboxContractNames.jsx";
import useJwt from "../../components/hooks/useJwt.jsx";
import useStudyTypeNames from "../../components/hooks/useStudyTypeNames.jsx";
import Alert from "../../shared/react/Alert.jsx";
import DialogTitleBar from "../../shared/react/DialogTitleBar.jsx";
import SwitchInput from "../../shared/react/SwitchInput.jsx";

function ExportStudiesForm({sortedStudies, menuClose}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const displayableStudyTypes = useStudyTypeNames();
  const displayableInboxContracts = useInboxContractNames();
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Submit button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Properties for the CSV export
  //---------------------------------------------------------------------------
  const studyProperties = React.useMemo(() => {
    return [
      {formKey: "id", label: "Study ID", defaultValue: true},
      {
        formKey: "orderNumber",
        label: "Order Number",
      },
      {
        formKey: "deviceSerial",
        getProperty: (study) => study.currentEnrollment?.tzSerial,
        label: "Device Serial",
        defaultValue: true,
      },
      {
        formKey: "deviceEnrollmentId",
        getProperty: (study) => study.currentEnrollment?.deviceEnrollmentId,
        label: "Recording ID",
      },
      {
        formKey: "studyType",
        getProperty: (study) => displayableStudyTypes[study.studyType] || study.studyType,
        label: "Study Type",
      },
      {
        formKey: "studyStatus",
        getProperty: (study) => study.studyStatus || "Unknown",
        label: "Study Status",
        defaultValue: true,
      },
      {
        formKey: "studyCreationDate",
        getProperty: (study) =>
          study.createdAt
            ? formatDateAndTime({
                datetime: study.createdAt,
                zone: study.timeZone,
              })
            : "",
        label: "Study Creation Date",
        defaultValue: true,
      },
      {
        formKey: "studyStartDate",
        getProperty: (study) =>
          study.studyStartDate
            ? formatDateAndTime({
                datetime: study.studyStartDate,
                zone: study.timeZone,
              })
            : "",
        label: "Study Start Date",
        defaultValue: true,
      },
      {
        formKey: "studyEndDate",
        getProperty: (study) =>
          study.studyEndDate ? formatDateAndTime({datetime: study.studyEndDate, zone: study.timeZone}) : "",
        label: "Study End Date",
        defaultValue: true,
      },

      {
        formKey: "configuredDuration",
        label: "Prescribed Study Duration",
        csvLabel: "Prescribed Study Duration (hours)",
        defaultValue: true,
      },
      {
        formKey: "recordedDuration",
        label: "Recorded Duration",
        csvLabel: "Recorded Duration (hours)",
      },
      ...(features.downgradeAuthorized
        ? [
            {
              formKey: "downgradeAuthorized",
              getProperty: (study) => {
                if (study.downgradeAuthorized === null) {
                  return "None";
                }
                return study.downgradeAuthorized ? "Yes" : "No";
              },
              label: "Downgrade Authorization",
            },
          ]
        : []),
    ];
  }, [displayableStudyTypes, features]);

  const patientAndOrderingPhysicianProperties = React.useMemo(() => {
    return [
      {
        formKey: "patientName",
        getProperty: (study) => study.studyDetails?.patientName,
        label: "Patient Name",
        defaultValue: true,
      },
      {
        formKey: "patientId",
        getProperty: (study) => study.studyDetails?.patientId,
        label: "Patient ID",
      },
      {
        formKey: "patientLanguage",
        getProperty: (study) => study.studyDetails?.patientLanguage,
        label: "Preferred Language",
      },
      {
        formKey: "patientDob",
        getProperty: (study) => study.studyDetails?.patientDob?.replaceAll("/", "-"), // @TODO BN-3380: remove replaceAll when date is stored in ISO
        label: "Patient DOB",
        defaultValue: true,
      },
      {
        formKey: "patientGender",
        getProperty: (study) => study.studyDetails?.patientGender,
        label: "Patient Gender",
      },
      {
        formKey: "patientHeight",
        getProperty: (study) => study.studyDetails?.patientHeight,
        label: "Patient Height",
      },
      {
        formKey: "patientWeight",
        getProperty: (study) => study.studyDetails?.patientWeight,
        label: "Patient Weight",
      },
      {
        formKey: "patientPhone",
        getProperty: (study) => study.studyDetails?.patientPhoneNumber,
        label: "Patient Phone",
      },
      {
        formKey: "patientAddress",
        getProperty: (study) => study.studyDetails?.patientAddress,
        label: "Patient Address",
      },
      {
        formKey: "orderingPhysicianName",
        getProperty: (study) => study.studyDetails?.physicianName,
        label: "Ordering Physician Name",
        defaultValue: true,
      },
      {
        formKey: "orderingPhysicianPhone",
        getProperty: (study) => study.studyDetails?.physicianPhoneNumber,
        label: "Ordering Physician Phone",
      },
      {
        formKey: "orderingPhysicianEmail",
        getProperty: (study) => study.studyDetails?.physicianEmail,
        label: "Ordering Physician Email",
      },
      {
        formKey: "orderingPhysicianFacility",
        getProperty: (study) => study.studyDetails?.physicianEmail,
        label: "Ordering Physician Facility",
      },
      {
        formKey: "orderingPhysicianAddress",
        getProperty: (study) => study.studyDetails?.physicianAddress,
        label: "Ordering Physician Address",
      },
    ];
  }, []);

  const billingProperties = React.useMemo(() => {
    return [
      {
        formKey: "facilityName",
        getProperty: (study) => study.facility.name,
        label: "Facility Name",
        defaultValue: true,
      },
      {
        formKey: "facilityAddress",
        getProperty: (study) => {
          const street = study.facility?.address?.street || "";
          const city = study.facility?.address?.city || "";
          const state = study.facility?.address?.state || "";
          const zipCode = study.facility?.address?.zipCode || "";
          const country = study.facility?.address?.country || "";

          return `${street} ${city}${city && state ? "," : ""} ${state} ${zipCode} ${country}`;
        },
        label: "Facility Address",
      },
      {
        formKey: "facilityZipCode",
        getProperty: (study) => study.facility.address.zipCode,
        label: "Facility Zip Code",
      },
      {
        formKey: "finalizedAt",
        getProperty: (study) =>
          study.finalizedAt
            ? formatDateAndTime({
                datetime: study.finalizedAt,
                zone: study.timeZone,
              })
            : "",
        label: "Finalized At",
        defaultValue: true,
      },
      {
        formKey: "turnaroundTime",
        getProperty: (study) =>
          study.finalizedAt && study.studyEndDate
            ? Math.ceil(
                Duration.fromObject({
                  milliseconds:
                    new Date(study.finalizedAt).getTime() - new Date(study.studyEndDate).getTime(),
                }).as("hours")
              )
            : "",
        label: "Turnaround Time",
        csvLabel: "Turnaround Time (hours)",
      },
      {
        formKey: "studyIndication",
        label: "Study Indication",
      },
      {
        formKey: "inboxContract",
        getProperty: (study) => displayableInboxContracts[study.inboxContract] || "",
        label: "Inbox Contract",
        defaultValue: true,
      },
      {
        formKey: "inventoryManagementWorkflow",
        getProperty: (study) => (study.facility?.returnToVendor ? "Return to Vendor" : "Return to Clinic"),
        label: "Inventory Management",
        csvLabel: "Inventory Management Workflow",
      },
    ];
  }, [displayableInboxContracts]);

  //---------------------------------------------------------------------------
  // Unifying the forms default values
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => {
    return [...studyProperties, ...patientAndOrderingPhysicianProperties, ...billingProperties].reduce(
      (defaults, {formKey, defaultValue}) => {
        return {...defaults, [formKey]: !!defaultValue};
      },
      {}
    );
  }, [studyProperties, patientAndOrderingPhysicianProperties, billingProperties]);

  //---------------------------------------------------------------------------
  // Save Toggled on fields to local storage
  //---------------------------------------------------------------------------
  const {username} = useJwt();
  const [initialValues, setInitialValues] = useLocalStorage(
    `${username}.studies.export.properties`,
    defaultValues
  );

  //---------------------------------------------------------------------------
  // Dialog state management
  //---------------------------------------------------------------------------
  const {handleSubmit, control, reset: setFormValues} = useForm();

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setFormValues(initialValues);
    setOpen(true);
  }, [setFormValues, initialValues]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    menuClose();
  }, [menuClose]);

  // ---------------------------------------------------------------------------
  // Select and deselect all handlers
  // ---------------------------------------------------------------------------
  const selectAll = React.useCallback(
    (_event, newState = true) => {
      setFormValues(
        Object.keys(initialValues).reduce((toggleValues, key) => ({...toggleValues, [key]: newState}), {})
      );
    },
    [setFormValues, initialValues]
  );

  const deselectAll = React.useCallback((_event) => selectAll(_event, false), [selectAll]);

  // ---------------------------------------------------------------------------
  // Handle export
  // ---------------------------------------------------------------------------
  const exportStudies = React.useCallback(
    (data) => {
      setLoading(true);

      // Make sure that there is at least one study field switched on
      const atLeastOneStudyField = Object.values(data).some((field) => field === true);

      if (!atLeastOneStudyField) {
        setError("There must be at least one selected study field");
      } else {
        try {
          const csvHeader = [];
          [...studyProperties, ...patientAndOrderingPhysicianProperties, ...billingProperties].forEach(
            ({formKey, label, csvLabel}) => {
              if (data[formKey]) {
                csvHeader.push(csvLabel || label);
              }
            }
          );

          const csvRows = [csvHeader];

          sortedStudies.forEach((study) => {
            const row = [];
            [...studyProperties, ...patientAndOrderingPhysicianProperties, ...billingProperties].forEach(
              ({formKey, getProperty}) => {
                if (data[formKey]) {
                  row.push((getProperty ? getProperty(study) : study[formKey]) ?? "");
                }
              }
            );
            csvRows.push(row);
          });

          const exportedStudiesCsv = csvRows.reduce((csv, columns) => {
            const row = columns
              .map((columnData) => {
                let wrappedData = columnData;
                if (typeof wrappedData !== "string") {
                  wrappedData = wrappedData.toString();
                }

                if (wrappedData.match(/"|,/g)) {
                  wrappedData = `"${wrappedData.replace(/"/g, `""`)}"`;
                } else {
                  wrappedData = `="${wrappedData}"`;
                }

                return wrappedData;
              })
              .join(",");

            return `${csv}${row}${row ? "\n" : ""}`;
          }, "");

          const file = new Blob([exportedStudiesCsv], {type: "text/csv"});
          const hiddenElement = document.createElement("a");
          hiddenElement.href = URL.createObjectURL(file);
          hiddenElement.target = "_blank";
          hiddenElement.download = "studies.csv";
          hiddenElement.click();

          // This will update local storage
          setInitialValues(data);
          handleClose();
        } catch (err) {
          setError(err.message);
        }
      }

      setLoading(false);
    },
    [
      sortedStudies,
      handleClose,
      studyProperties,
      patientAndOrderingPhysicianProperties,
      billingProperties,
      setInitialValues,
    ]
  );

  //---------------------------------------------------------------------------
  // Render the menu item and the dialogue pop-up
  //---------------------------------------------------------------------------
  return (
    <>
      <MenuItem data-cy="export-button" onClick={handleOpen}>
        <SystemUpdateAltIcon color="tertiary" fontSize="small" />
        &nbsp;&nbsp;Export to CSV
      </MenuItem>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(exportStudies),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitleBar icon={<SystemUpdateAltIcon color="secondary" />} title="Export Studies to CSV" />

        <Divider sx={{bgcolor: "secondary.main"}} />

        <DialogContent sx={{pt: 2, pr: 0}}>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid size={7} sx={{pb: 1}}>
              Select which study properties to include in the export.
            </Grid>
            <Grid size={5}>
              <ButtonGroup variant="text" size="small" color="tertiary" sx={{ml: 17}}>
                <Button color="secondary" onClick={selectAll} data-cy="export-select-all">
                  Select All
                </Button>
                <Button onClick={deselectAll} data-cy="export-deselect-all">
                  Deselect All
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>

          <Grid container sx={{pl: 2, pt: 2}}>
            <Stack sx={{pr: 5}}>
              {studyProperties.map(({formKey, label}) => (
                <Grid key={formKey}>
                  <SwitchInput
                    control={control}
                    name={formKey}
                    label={label}
                    color="secondary"
                    data-cy={`csv-export-${formKey}`}
                  />
                </Grid>
              ))}
            </Stack>

            <Stack sx={{pr: 5}}>
              {patientAndOrderingPhysicianProperties.map(({formKey, label}) => (
                <Grid key={formKey}>
                  <SwitchInput
                    control={control}
                    name={formKey}
                    label={label}
                    color="secondary"
                    data-cy={`csv-export-${formKey}`}
                  />
                </Grid>
              ))}
            </Stack>

            <Stack>
              {billingProperties.map(({formKey, label}) => (
                <Grid key={formKey}>
                  <SwitchInput
                    control={control}
                    name={formKey}
                    label={label}
                    color="secondary"
                    data-cy={`csv-export-${formKey}`}
                  />
                </Grid>
              ))}
            </Stack>
          </Grid>
        </DialogContent>

        <DialogActions sx={{pb: 2, pr: 2, pt: 0}}>
          <Button color="secondary" onClick={handleClose} data-cy="export-studies-cancel-button">
            Cancel
          </Button>
          <LoadingButton
            data-cy="export-studies-submit-button"
            disabled={loading}
            variant="contained"
            color="secondary"
            loading={loading}
            type="submit"
          >
            Export
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

ExportStudiesForm.propTypes = {
  sortedStudies: PropTypes.array.isRequired,
  menuClose: PropTypes.func.isRequired,
};

export default ExportStudiesForm;
