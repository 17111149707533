import React from "react";
import {useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";
import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Add from "@mui/icons-material/Add";
import ExpandMore from "@mui/icons-material/ExpandMore";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AutocompleteInput from "../../shared/react/AutocompleteInput.jsx";
import FormDateTimeInput from "../../shared/react/FormDateTimeInput.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import LinkifyText from "../../shared/react/LinkifyText.jsx";

const ordinalNames = ["Primary", "Secondary", "Tertiary"];

function InsuranceInfo({
  // Props
  insuranceTypes = [],
  readOnly = false,
  study = {},
  requiredFields,
}) {
  const getInsuranceInfoFields = React.useCallback(
    (insurance) => {
      const validPatientGenderFieldOptions = ["", "Male", "Female"];
      return [
        {
          id: "insuranceCompany",
          label: "Insurance Company",
          xs: 12,
          sm: 6,
        },
        {
          id: "subscriberIdNumber",
          label: "Subscriber ID Number",
          xs: 12,
          sm: 6,
        },
        {
          id: "subscriberName",
          label: "Subscriber Name",
          xs: 12,
          sm: readOnly ? 12 : 4,
        },
        {
          id: "subscriberDob",
          label: "Subscriber DOB",
          isDate: true,
          xs: 6,
          sm: readOnly ? 6 : 3,
          rules: {
            validate: {
              isValidDoB: (subscriberDob) => {
                if (subscriberDob) {
                  if (subscriberDob.invalid) {
                    return "Subscriber date of birth must be in a valid format";
                  }
                  if (
                    !subscriberDob
                      .toFormat("yyyy/MM/dd")
                      .match("^(([1-2][0-9][0-9][0-9])/(1[0-2]|0[1-9])/(0[1-9]|[1-2][0-9]|3[0-1]))?$")
                  ) {
                    return "Subscriber date of birth is not a valid date";
                  }
                  if (subscriberDob > DateTime.local()) {
                    return "Subscriber date of birth cannot be in the future";
                  }
                }
                return true;
              },
            },
          },
        },
        {
          id: "subscriberSex",
          label: "Subscriber Sex Designated at Birth",
          xs: 6,
          sm: readOnly ? 6 : 5,
          options: [
            {name: "<None Selected>", id: ""},
            {name: "Male", id: "Male"},
            {name: "Female", id: "Female"},
            ...(!validPatientGenderFieldOptions.includes(insurance?.subscriberSex || "")
              ? [
                  {
                    name: insurance.subscriberSex,
                    id: insurance.subscriberSex,
                    disabled: true,
                  },
                ]
              : []),
          ],
          rules: {
            validate: {
              isValidSex: (sex) =>
                validPatientGenderFieldOptions.includes(sex) ||
                "Please select a valid option from the dropdown",
            },
          },
        },
        {
          id: "subscriberAddress",
          label: "Subscriber Address",
          xs: 12,
        },
        {
          id: "policyNumber",
          label: "Policy/ID Number",
          xs: 12,
          sm: readOnly ? 6 : 4,
        },
        {id: "groupNumber", label: "Group Number", xs: 12, sm: readOnly ? 6 : 4},
        {id: "priorAuthorizationNumber", label: "Prior Authorization Number", xs: 12, sm: readOnly ? 6 : 4},
        {
          id: "claimsPhoneNumber",
          label: "Claims Phone Number",
          xs: 12,
          sm: readOnly ? 6 : 3,
          rules: {
            validate: {
              isValidPhone: (phone) =>
                !phone || isMobilePhone(phone) ? true : "Phone must be a valid phone number",
            },
          },
        },
        {
          id: "claimsAddress",
          label: "Claims Address",
          xs: 12,
          sm: readOnly ? 12 : 9,
        },
        {
          id: "additionalComments",
          label: "Additional Comments",
          xs: 12,
          link: true,
          otherProps: {multiline: true, maxRows: 4},
        },
      ];
    },
    [readOnly]
  );

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control} = useFormContext();

  //---------------------------------------------------------------------------
  // Expanding insurance card state management
  //---------------------------------------------------------------------------
  const [insurances, setInsurances] = React.useState(
    study?.insurance?.length > 0 ? study.insurance : [{priority: 1}]
  );

  // This is a bit of a band-aid, but using the state in read-only mode was causing insurance info to "disappear"
  const insurancesToDisplay = React.useMemo(() => {
    if (!readOnly) {
      return insurances;
    }
    if (!study?.insurance?.length) {
      return [{priority: 1}];
    }
    return study.insurance;
  }, [insurances, readOnly, study.insurance]);

  const [expanded, setExpanded] = React.useState(1);
  const expandedHandlers = React.useMemo(
    () =>
      insurancesToDisplay.map((insurance) => {
        return (event, isExpanded) => {
          if (isExpanded) {
            setExpanded(insurance.priority);
          }
        };
      }),
    [insurancesToDisplay]
  );

  //---------------------------------------------------------------------------
  // Handle removing and adding supplemental insurance policies
  //---------------------------------------------------------------------------
  const handleRemove = React.useCallback(
    async (priority) => {
      setInsurances(insurancesToDisplay.filter((insurance) => insurance.priority !== priority));
      setExpanded(priority - 1);
    },
    [insurancesToDisplay, setInsurances]
  );

  const handleAddInsurance = React.useCallback(() => {
    const priority = insurancesToDisplay.length + 1;
    setInsurances(insurancesToDisplay.concat({priority}));
    setExpanded(priority);
  }, [insurancesToDisplay, setInsurances]);

  return (
    <>
      {insurancesToDisplay.map((insurance, index) => (
        <Accordion
          key={insurance.priority}
          square
          expanded={expanded === insurance.priority}
          onChange={expandedHandlers[index]}
          disableGutters
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-cy={`insurance-${insurance.priority}-summary`}>
            <Typography>{`${ordinalNames[insurance.priority - 1] || "Additional"} Insurance`}</Typography>
          </AccordionSummary>
          <AccordionDetails data-cy={`insurance-${insurance.priority}-details`}>
            <Grid container spacing={2} sx={{alignItems: "flex-start"}}>
              <Grid size={{xs: 12, sm: 6}}>
                {readOnly ? (
                  <Typography variant="body2" id="insuranceTypeValue">
                    <b>Insurance Type</b>: {insurance.type?.name || insurance.insuranceTypeName}
                  </Typography>
                ) : (
                  <FormStringInput
                    disabled={readOnly}
                    required={requiredFields?.insuranceType}
                    control={control}
                    defaultValue={insurance.insuranceType || ""}
                    label="Insurance Type"
                    name={`insurance[${insurance.priority - 1}].insuranceType`}
                    id="insuranceTypeSelect"
                    options={[{name: <em>None</em>, id: ""}, ...insuranceTypes]}
                    rules={{...(requiredFields?.insuranceType && {required: "Insurance Type is required"})}}
                  />
                )}
              </Grid>
              <Grid size={{xs: 12, sm: 6}}>
                {readOnly ? (
                  <Typography variant="body2" id="relationToPatientValue">
                    <b>Relation to Patient</b>: {insurance.relationToPatient}
                  </Typography>
                ) : (
                  <AutocompleteInput
                    variant="single"
                    name={`insurance[${insurance.priority - 1}].relationToPatient`}
                    control={control}
                    defaultValue={insurance.relationToPatient ? insurance.relationToPatient : null}
                    id="relationToPatientSelect"
                    options={["Self", "Parent/Guardian", "Spouse"]}
                    label="Relation to Patient"
                    required={requiredFields?.relationToPatient}
                    otherProps={{
                      freeSolo: true,
                      autoSelect: true,
                    }}
                    getOptionLabel={(option) => option}
                    rules={{
                      ...(requiredFields?.relationToPatient && {required: "Relation to patient is required"}),
                    }}
                  />
                )}
              </Grid>
              {getInsuranceInfoFields(insurance).map((field) => {
                if (readOnly) {
                  return (
                    <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
                      <Typography variant="body2" id={`${field.id}Value`}>
                        <b>{field.label}</b>:{" "}
                        {field.link ? <LinkifyText text={insurance[field.id]} /> : insurance[field.id]}
                      </Typography>
                    </Grid>
                  );
                }

                if (field.isDate) {
                  return (
                    <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
                      <FormDateTimeInput
                        disabled={readOnly}
                        required={requiredFields?.[field.id]}
                        defaultValue={
                          insurance?.[field.id]
                            ? DateTime.fromFormat(insurance?.[field.id], "yyyy/MM/dd")
                            : null
                        }
                        control={control}
                        label={field.label}
                        name={`insurance[${insurance.priority - 1}].${field.id}`}
                        id={`${field.id}Input`}
                        views={["year", "month", "day"]}
                        disableFuture
                        rules={{
                          ...field.rules,
                          ...(requiredFields?.[field.id] && {required: `${field.label} is required`}),
                        }}
                      />
                    </Grid>
                  );
                }

                return (
                  <Grid key={field.id} size={{xs: field.xs, sm: field.sm}}>
                    <FormStringInput
                      disabled={readOnly}
                      required={requiredFields?.[field.id]}
                      control={control}
                      defaultValue={insurance[field.id] || ""}
                      label={field.label}
                      name={`insurance[${insurance.priority - 1}].${field.id}`}
                      id={`${field.id}Input`}
                      rules={{
                        ...field.rules,
                        ...(requiredFields?.[field.id] && {required: `${field.label} is required`}),
                      }}
                      options={field.options}
                    />
                  </Grid>
                );
              })}
              {!readOnly && insurances.length > 1 && insurance.priority === insurances.length && (
                <Grid sx={{justifyItems: "end"}}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRemove(insurance.priority)}
                    data-cy="remove-insurance-button"
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      {!readOnly && insurances.length < 3 && (
        <Box sx={{mt: 2}}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={handleAddInsurance}
            data-cy="add-insurance-button"
          >
            Add Insurance Policy
          </Button>
        </Box>
      )}
    </>
  );
}

InsuranceInfo.propTypes = {
  insuranceTypes: PropTypes.array,
  readOnly: PropTypes.bool,
  study: PropTypes.object,
  requiredFields: PropTypes.object.isRequired,
};

export default InsuranceInfo;
